.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
  }
  
  .footer-logo {
    width: 120px; /* Adjust logo size */
  }
  
  .footer-link {
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 15px;
  }
  
  .footer-link:hover {
    color: #d2d2d2; /* Hover effect for links */
  }
  
  .social-icon {
    color: #fff;
    margin: 0 10px;
  }
  
  .social-icon:hover {
    color: #d2d2d2; /* Hover effect for social icons */
  }
  