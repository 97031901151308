@font-face {
    font-family: 'LibreFranklin';
    src: url('../../../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.hero-section {
    background-image: url('https://media.istockphoto.com/id/1581309911/photo/high-angle-view-of-a-warehouse-manager-walking-with-foremen-checking-stock-on-racks.jpg?s=612x612&w=0&k=20&c=JCF-qJiwTUAhD8sEgEhHNcJZvxy4JtIWHYeQLKcbcKk='); /* Replace with actual path */
    background-size: cover;
    background-position: center;
    height: 50vh;
    width: 100%;
    position: relative;
    font-family: 'LibreFranklin', sans-serif;
  }
  
  .hero-section .container {
    z-index: 2;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Optional overlay to improve text readability */
    z-index: 1;
  }
  
  h1, p {
    z-index: 2; /* Ensure text is above overlay */
  }
  
  button {
    z-index: 2; /* Ensure button is above overlay */
  }
  