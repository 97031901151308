@font-face {
    font-family: 'LibreFranklin';
    src: url('../../../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.ServicesCards{
    background-color: rgb(239, 239, 239);
}

.title {
    font-family: 'LibreFranklin', sans-serif;
}

.text {
    
  text-align: justify;
}