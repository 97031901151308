/* src/components/CareerLink.css */
.career-link-section {
  background-color: #f8f9fa;
  padding: 50px 0; /* Similar padding for consistency */
}

.career-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 500px; /* Restrict image height to avoid overflow */
}

.career-text-block {
  text-align: center;
}

@media (min-width: 768px) {
  .career-text-block {
    text-align: left;
  }
}

.career-title {
  font-family: 'LibreFranklin', sans-serif;
  font-size: 2.5rem; /* Adjusted for larger displays */
  font-weight: 700;
  text-transform: uppercase;
  color: #ffc107;
}

.career-description {
  font-size: 1.2rem;
  margin-top: 20px;
  color: #333;
}

.explore-button {
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  border-radius: 0;
  font-size: 1.25rem;
}

.explore-button:hover {
  background-color: #ffc107;
  color: #000;
}
