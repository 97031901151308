/* src/Subscribe.css */
.subscribe-section {
    background-image: url('https://cdn.pixabay.com/photo/2022/02/24/21/05/ship-7033158_1280.jpg'); /* Replace with your background image path */
    background-size: cover;
    background-position: center;
    padding: 80px 0;
    color: #ffc107;
    text-align: center;
  }
  
  .subscribe-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
  }
  
  .subscribe-text {
    font-size: 18px;
    margin-bottom: 30px;
    color: white;
  }
  
  .subscribe-form {
    display: flex;
    justify-content: center;
  }
  
  .subscribe-input {
    width: 300px;
    margin-right: 10px;
    border-radius: 0;
    border: none;
    padding: 10px;
  }
  
  .subscribe-btn {
    border-radius: 0;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    .subscribe-input {
      width: 100%;
      margin-bottom: 10px;
    }
    .subscribe-btn {
      width: 100%;
    }
  }
  