/* src/components/CareerAbout.css */
.career-about-section {
  position: relative;
  background-image: url('https://www.ioscm.com/wp-content/uploads/2022/06/AdobeStock_65931251.jpeg');
  /* Replace with the correct path to your image */
  background-size: cover;
  background-position: center;
  height: 70vh;
  /* Adjust height as needed */
  display: flex;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  color: white;
  text-align: center;
  max-width: 800px;
}

.hashtag-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffc107;
  margin-bottom: 20px;
}

.about-description {
  font-size: 1rem;
  margin-bottom: 20px;
}

.explore-button {
  background-color: #000;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

.explore-button:hover {
  background-color: #444;
  /* Slightly lighter on hover */
}

@media (max-width: 768px) {
  .hashtag-title {
    font-size: 2rem;
  }
}