/* src/components/CareerBenefits.css */
.career-benefits-section {
    background-color: #f8f9fa; /* Light background for contrast */
    padding: 60px 0;
  }
  
  .career-benefits-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffc107;
    margin-bottom: 10px;
  }
  
  .career-benefits-subtitle {
    font-size: 1.1rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .benefit-card {
    border: none;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
  }
  
  .benefit-title {
    font-weight: bold;
    color: #ffc107 !important; /* Red color to match the example */
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .benefit-card .card-text {
    color: #333;
    font-size: 1rem;
  }
  