
.slider-image-container {
    position: relative;
    height: 600px;
  }
  .slider-image-container img {
    max-height: 100%;
    width: 100%;
    object-fit: cover; /* Ensures the image covers the entire container without distortion */
  }
  
  .shadow-lg {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  }
  
  .carousel-caption {
    position: absolute;
     top: 50%;
    text-align: center;
  }
  
  .carousel-caption h3, .carousel-caption p {
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }
  
  .carousel-caption .btn {
    margin-top: 15px;
  }
  @media (max-width: 768px) {
    .carousel-caption {
      top: auto;
    }

    .slider-image-container {
      position: relative;
      height: 100%;
    }
  }
  