/* Container for the gallery */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
    margin-top: 70px;
  }
  
  .container .box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .container .box .dream {
    display: flex; 
    flex-direction: column;
    width: 32.5%; 
  }
  
  .container .box .dream img {
    width: 100%;
    padding: 2px;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin: 0;
  }
  
  .container .box .dream img:hover {
    transform: scale(1.05);
  }
  
  /* Lightbox (modal) styles */
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox-content {
    max-width: 80%;
    max-height: 80%;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
    transition: transform 0.3s ease;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
  }
  
  .close:hover {
    color: #f0f0f0;
    transform: scale(1.1);
  }
  
  @media (max-width: 600px) {
    .container .box {
      flex-direction: column;
    }
  
    .container .box .dream {
      width: 100%;
    }
  
    .lightbox-content {
      max-width: 90%;
      max-height: 90%;
    }
    .container .box .dream img:hover {
      transform: none;
    }
  }
  