/* src/components/BannerBlock.css */
.banner-block {
    background-color: rgb(239, 239, 239);
    padding: 50px 0;
  }
  
  .banner-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 500px; /* Restrict image height to avoid overflow */
  }
  
  .text-block {
    /* padding: 20px !important; */
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .text-block {
      text-align: left;
    }
  }
  
  .text-block h1 {
    font-family: 'LibreFranklin', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .text-block p {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  .text-block h5 {
    font-style: italic;
    font-weight: 400;
    color: #555;
  }
  