@font-face {
  font-family: 'LibreFranklin';
  src: url('../../../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'LibreFranklin', sans-serif;
}

.contact-form-container {
  background-image: url('https://media.istockphoto.com/id/1581309911/photo/high-angle-view-of-a-warehouse-manager-walking-with-foremen-checking-stock-on-racks.jpg?s=612x612&w=0&k=20&c=JCF-qJiwTUAhD8sEgEhHNcJZvxy4JtIWHYeQLKcbcKk=');
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contact-form-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}

.contact-form {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  max-width: 900px;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.company-info {
  color: #333;
  z-index: 2;
  margin-bottom: 20px;
}

.company-info h2 {
  font-size: 2rem;
  color: rgb(128, 128, 128);
  font-weight: bold;
}

.company-info p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 15px;
  }

  .company-info h2 {
    font-size: 1.5rem;
  }
}
