/* src/components/CareerImage.css */
.career-image-section {
    background-image: url('https://www.dvfu.ru/upload/iblock/56c/741d7274d92d51d01cebb57373f4af55.jpeg'); /* Replace with the path to your image */
    background-size: cover;
    background-position: center;
    position: relative;
    height: 70vh; /* Adjust height as needed */
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Creates the dark overlay */
    display: flex;
    align-items: flex-end;
  }
  
  .text-container {
    text-align: center;
    color: white;
    margin-bottom: 20px; /* Adjust as needed */
  }
  
  .career-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .career-subtitle {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  