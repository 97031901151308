@font-face {
  font-family: 'LibreFranklin';
  src: url('../../../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.logo-image {
  height: 50px;
  width: auto;
  display: block;
  margin-left: 100px;
}

.navbar-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffc107 !important;
  font-family: 'LibreFranklin', sans-serif;
}

.custom-navbar {
  background-color: rgb(45, 43, 44);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navUl {
  margin-right: 200px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 1.5rem;
  color: #ffc107;
  transition: color 0.3s ease;
  font-family: 'LibreFranklin', sans-serif;
}

.navbar-nav .nav-link:hover {
  color: #d2d2d2;
}

.navbar-toggler {
  border: none;
  transition: transform 0.3s ease-in-out;
}

.navbar-toggler.collapsed {
  transform: rotate(0deg);
}

.navbar-toggler:not(.collapsed) {
  transform: rotate(180deg);
}

.navbar-nav .nav-link.active {
  color: #8b572a;
}

@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
  }

  .navbar-nav .nav-link {
    margin-bottom: 0.5rem;
  }

  .navbar-text {
    font-size: 1.2rem;
  }

  .navUl {
    margin-right: 0px;
  }

  .logo-image {
    margin-left: 0px;
  }

  .navbar-toggler {
    background-color: #ffc107 !important;
    box-shadow: none !important;
  }
}

@media (max-width: 375px) {
  .navbar-text {
    font-size: 0.9rem;
  }
  .navbar-toggler {
    font-size: 1rem !important;
  }

  .navbar-brand {
    margin: 0 !important;
  }
}
