@font-face {
  font-family: 'LibreFranklin';
  src: url('../../../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.about-hero {
  position: relative;
  background-image: url('https://cdn.pixabay.com/photo/2021/02/03/02/46/landscape-5976337_1280.jpg');
  /* Replace with actual image path */
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
  color: white;
}

.hero-text {
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: left;
  /* Aligns text to the left */
  margin-left: 100px;
  /* Push the text away from the left side */
}

.hero-text h1 {
  font-size: 3rem;
  font-family: 'LibreFranklin', sans-serif;
}


.about-sidebar ul {
  list-style: none;
  padding: 0;
}

.about-sidebar ul li a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  display: block;
  padding: 10px 0;
}

.about-sidebar ul li a:hover {
  color: #ff0000;
  /* Optional hover effect */
}

h2 {
  font-weight: bold;
}

.col p {
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.6;
}