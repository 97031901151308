.video-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: rgb(239, 239, 239);
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
