/* BlogCard.css */
.blog-card {
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .blog-card-title {
    font-size: 24px;
    font-weight: bold;
    color: #a31f34; /* Base color */
    margin-bottom: 10px;
  }
  
  .blog-card-date {
    font-size: 14px;
    color: #6c757d; /* Muted gray */
  }
  
  .blog-card-excerpt {
    font-size: 16px;
    color: #333;
    text-align: left;
  }
  
  .blog-card-btn {
    font-size: 16px;
    border-radius: 4px;
    padding: 8px 20px;
    border-color: #a31f34;
    color: #a31f34;
  }
  
  .blog-card-btn:hover {
    background-color: #a31f34;
    color: #fff;
  }
  